import { useQuery } from 'react-apollo';
import type { ApolloError, WatchQueryFetchPolicy } from 'apollo-client';
import type { GraphQLError } from 'graphql';

import { useIsLicensed } from '@confluence/session-data';
import { isUnauthorizedError, isStatusCodeError } from '@confluence/error-boundary';

import { SitePersonalizationQuery } from './SitePersonalizationQuery.experimentalgraphql';
import type {
	SitePersonalizationQuery as SitePersonalizationQueryData,
	SitePersonalizationQuery_experimentalSitePersonalization_attributes as SitePersonalizationAttributes,
	SitePersonalizationQueryVariables,
} from './__types__/SitePersonalizationQuery';

export type useSitePersonalizationQueryProps = {
	cloudId: string;
	skip?: boolean;
	fetchPolicy?: WatchQueryFetchPolicy;
};

export let skipQueryIfUnauthorized = false;

export const useSitePersonalizationQuery = ({
	cloudId,
	skip,
	fetchPolicy,
}: {
	cloudId: string;
	skip?: boolean;
	fetchPolicy?: WatchQueryFetchPolicy;
}) => {
	const isLicensed = useIsLicensed();

	const skipOptions =
		skip ||
		!isLicensed ||
		skipQueryIfUnauthorized ||
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		Boolean(process.env.REACT_SSR); // SitePersonalizationQuery isn't preloaded and can be skipped during SSR to avoid unnecessary requests

	const { data, loading, error } = useQuery<
		SitePersonalizationQueryData,
		SitePersonalizationQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SitePersonalizationQuery,
		{
			variables: {
				cloudId,
			},
			fetchPolicy: fetchPolicy || 'cache-first',
			skip: skipOptions,
			onError(apolloError: ApolloError) {
				skipQueryIfUnauthorized = apolloError?.graphQLErrors?.some(
					(graphQLError: GraphQLError) =>
						isStatusCodeError(graphQLError, '401') || isUnauthorizedError(graphQLError),
				);
			},
		},
	);
	const attributes: SitePersonalizationAttributes[] =
		data?.experimentalSitePersonalization?.attributes || [];
	return {
		loading,
		error,
		attributes,
	};
};
