import type { VFC } from 'react';
import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';

import { NavSkeleton } from '../src/Nav3/NavSkeleton';
// WARN: import type is deleted upon TS compilation, so AppNavigation
// wrapped into Loadable remains behind the code split.
import type { AppNavigationProps } from '../src/Nav3/AppNavigation';

export const AppNavigationBaseLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AppNavigation" */ '../src/Nav3/AppNavigation'))
			.AppNavigation,
	loading: NavSkeleton as any,
});

const AppNavigationBaseComponent: VFC<AppNavigationProps> = (props) => (
	<>
		<PageSegmentLoadStart metric={APP_NAVIGATION_METRIC} />
		<AppNavigationBaseLoader {...props} />
	</>
);

export const AppNavigationBase = React.memo(AppNavigationBaseComponent);
