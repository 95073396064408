export const ONBOARDING_STATE_PREFIX = 'onboarding-state:';
export const PEOPLE = 'peopleNudge';
export const LEGACY = 'legacy';
export const INTRO_WORKFLOW = 'introWorkflow';
export const FIRST_SPACE_SELECTED = 'firstSpaceSelected';
export const TEAM_SPACE_CREATED = 'teamSpaceCreated';

export const HELP = 'help';
export const ONBOARDING_INTENTS = 'onboardingIntents';
export const IS_FOUNDING_ADMIN = 'isFoundingAdmin';
export const IS_SAMPLE_CONTENT_V2_SPOTLIGHT_VISIBLE = 'isSampleContentV2SpotlightVisible';
export const IS_MORE_ACTIONS_TOUR_VISIBLE = 'isMoreActionsTourVisible';
export const IS_MORE_ACTIONS_HINT_VISIBLE = 'isMoreActionsHintVisible';
export const LAND_ON_PATH = 'landOnPath';
export const ONBOARDING_INTENT_EXPERIENCES = {
	ONBOARDING_INTENT_EXPERIENCES_CLOSED:
		'onboarding-intent-experiences:onboardingIntentExperienceClosed',
	ONBOARDING_EXPERIENCE_ELIGIBLE: 'onboarding-intent-experience:isOnboardingExperienceEligibleUser',
	ONBOARDING_INTENT_EXPERIENCES_LAND_NEW_HOME_VIEWED_AT:
		'onboarding-intent-experiences:onboardingIntentExperienceLandViewedAt',
	ONBOARDING_INTENT_EXPERIENCES_LAND_NEW_HOME_CLOSED:
		'onboarding-intent-experiences:onboardingIntentExperienceLandClosed',
};

export const ONBOARDING_REVERSE_TRIAL = {
	MODAL_SEEN: 'onboarding-reverse-trial:isModalSeen',
};

export const TEMPLATES_COLLECTION = {
	EXPERIMENT_ELIGIBLE: 'templates-collection:isTaggedForTemplatesCollectionExperiment',
	EXPERIMENT_DISMISSED: 'templates-collection:isTemplatesSectionDismissed',
};

export const SPACE_GETTING_STARTED_TOUR_EXPERIENCES = {
	IS_DISMISSED: 'space-getting-started-tour:tourIsDismissed',
};

export const PERSISTENT_INVITE = {
	HAS_INVITE_CAPABILITIES: 'persistent-invite:hasInviteCapabilities',
};

export const IMPROVED_GET_STARTED_PATH = 'improvedGetStartedPath';
export const IMPROVED_GET_STARTED_WHITEBOARD_PATH = 'improvedGetStartedWhiteboardPath';

export const IS_REDACTIONS_TOUR_VISIBLE = 'isRedactionsTourVisible';

export const WHATS_NEW_IN_CONFLUENCE = {
	IS_SPOTLIGHT_DISMISS: 'whats-new-in-confluence:isWhatsNewInConfluneceSpotlightDismissed',
	PULSE_SEEN_AT: 'whats-new-in-confluence:whatsNewInConfluencePulseSeenAt',
};

export const NTH_USER_SETUP_CONTENT_TYPE = {
	NTH_USER_SELECTED_CONTENT_TYPE: 'nth-user-setup:nthUserSelectedContentType',
	NTH_USER_CONTENT_TYPE_SPOTLIGHT_SEEN: 'nth-user-setup:nthUserContentTypeSpotlightSeen',
};

// existing onboarding experiences for making query calls
export const ONBOARDING_EXPERIENCES = {
	INTRO_WORKFLOW: `${ONBOARDING_STATE_PREFIX}${INTRO_WORKFLOW}`,
	LEGACY_ONBOARDING_STATE: `${ONBOARDING_STATE_PREFIX}${LEGACY}`,
	IS_FOUNDING_ADMIN: `${ONBOARDING_STATE_PREFIX}${IS_FOUNDING_ADMIN}`,
	IS_SAMPLE_CONTENT_V2_SPOTLIGHT_VISIBLE: `${ONBOARDING_STATE_PREFIX}${IS_SAMPLE_CONTENT_V2_SPOTLIGHT_VISIBLE}`,
	LAND_ON_PATH: `${ONBOARDING_STATE_PREFIX}${LAND_ON_PATH}`,
	TEAM_SPACE_CREATED: `${ONBOARDING_STATE_PREFIX}${TEAM_SPACE_CREATED}`,
	IS_MORE_ACTIONS_TOUR_VISIBLE: `${ONBOARDING_STATE_PREFIX}${IS_MORE_ACTIONS_TOUR_VISIBLE}`,
	IS_MORE_ACTIONS_HINT_VISIBLE: `${ONBOARDING_STATE_PREFIX}${IS_MORE_ACTIONS_HINT_VISIBLE}`,
	IMPROVED_GET_STARTED_PATH: `${ONBOARDING_STATE_PREFIX}${IMPROVED_GET_STARTED_PATH}`,
	IMPROVED_GET_STARTED_WHITEBOARD_PATH: `${ONBOARDING_STATE_PREFIX}${IMPROVED_GET_STARTED_WHITEBOARD_PATH}`,
	IS_REDACTIONS_TOUR_VISIBLE: `${ONBOARDING_STATE_PREFIX}${IS_REDACTIONS_TOUR_VISIBLE}`,
};

// existing experience status
export const COMPLETE = '__complete__';

export const { LEGACY_ONBOARDING_STATE } = ONBOARDING_EXPERIENCES;
