import type { FC } from 'react';
import React, { Fragment, useCallback, useState, useContext } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { LeftSidebar, usePageLayoutResize } from '@atlaskit/page-layout';

import { useQuickstartState } from '@confluence/onboarding-quickstart';
import {
	ShortcutListener,
	GeneralShortcutListener,
	ShortcutVisualizer,
	SIDE_NAVIGATION_SHORTCUT,
	SIDE_NAVIGATION_SHORTCUT_IN_EDIT,
} from '@confluence/shortcuts';
import {
	SpaceNavigation,
	SpaceNavigationLoadingSkeleton,
	AdminNavigationLoader,
} from '@confluence/side-navigation';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';
import { isDialogShown } from '@confluence/dialogs';
import { WHITEBOARD_IFRAME_TITLE } from '@confluence/whiteboard-utils/entry-points/whiteboardConstants';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { usePageSpaceKey } from '@confluence/page-context';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

import type { PageLayoutProps } from '../PageLayoutTypes';
import {
	CONTAINER_HOME_ID,
	CONTAINER_BLOG_ID,
	ADMIN_HOME_ID,
	CONTAINER_HOME_LOADING_ID,
} from '../paths';

type skipLinkProp = {
	skiplinkText: string;
};

const i18n = defineMessages({
	collapseLabel: {
		id: 'page-layout.sidebar.collapse.label',
		defaultMessage: 'Collapse sidebar',
		description: 'label text for a button that will close the side navigation bar',
	},
	expandLabel: {
		id: 'page-layout.sidebar.expand.label',
		defaultMessage: 'Expand sidebar',
		description: 'label text for a button that will open the side navigation bar',
	},
	collapseTooltip: {
		id: 'page-layout.sidebar.tooltip.collapse',
		defaultMessage: 'Collapse',
		description: 'tooltip text for a button that will close the side navigation bar',
	},
	expandTooltip: {
		id: 'page-layout.sidebar.tooltip.expand',
		defaultMessage: 'Expand',
		description: 'tooltip text for a button that will open the side navigation bar',
	},
});

const DEFAULT_SIDEBAR_WIDTH = 240;
const ExpandKeyboardShortcut = () => {
	const [readyForToggle, setReadyForToggle] = useState(true);
	const { isLeftSidebarCollapsed, expandLeftSidebar, collapseLeftSidebar } = usePageLayoutResize();
	const isOnEditRoute = useIsEditorPage();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	const toggleSidebarCollapse = useCallback(() => {
		if (readyForToggle) {
			if (isLeftSidebarCollapsed) {
				expandLeftSidebar();
			} else {
				collapseLeftSidebar();
			}

			setReadyForToggle(false);
			setTimeout(() => setReadyForToggle(true), 350);
		}
	}, [isLeftSidebarCollapsed, expandLeftSidebar, collapseLeftSidebar, readyForToggle]);

	const toggle = useCallback(() => {
		if (!isDialogShown()) {
			toggleSidebarCollapse();
			setReadyForToggle(false);
			setTimeout(() => setReadyForToggle(true), 350);
		}
	}, [toggleSidebarCollapse]);

	return isLiveEditMode || isOnEditRoute ? (
		<ShortcutListener accelerator={SIDE_NAVIGATION_SHORTCUT_IN_EDIT} listener={toggle} />
	) : (
		<GeneralShortcutListener accelerator={SIDE_NAVIGATION_SHORTCUT} listener={toggle} />
	);
};

//SidebarWrapper is a SUPER common Component name in AK
const confSidebarWrapperStyles = css({
	zIndex: 13,
	// NOTE: This seems to be overridden by @atlaskit SideNavigation - consider removing
	background: token('color.background.accent.gray.subtlest'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'nav[aria-label="space navigation"], nav[aria-label="admin navigation"]': {
		minWidth: 0,
	},

	// Remove resize button draggable area drop shadow

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-testid="grid-left-sidebar-shadow"]': {
		width: '1px',
		left: '1px',
		background: token('color.border'),
		opacity: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-testid="grid-left-sidebar-resize-button"]': {
		top: '18px',
		boxShadow: `0 0 0 1.2px ${token('color.background.accent.gray.subtler')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'#AkSideNavigation > div': {
		backgroundColor: token('elevation.surface'),
	},
});

const useSideNavState = () => {
	const { isPanelOpen, isDismissed } = useQuickstartState();
	const shouldForceExpand = isPanelOpen && !isDismissed;

	const persistedCollapseState =
		confluenceLocalStorageInstance.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_COLLAPSED,
		) ?? 'expanded';
	const persistedWidth = parseInt(
		confluenceLocalStorageInstance.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_WIDTH, //can return null
		) || DEFAULT_SIDEBAR_WIDTH,
		10,
	);

	const initialCollapsedState = shouldForceExpand ? 'expanded' : persistedCollapseState;

	const fittedSidebarWidth =
		persistedWidth > window.innerWidth ? DEFAULT_SIDEBAR_WIDTH : persistedWidth;

	return {
		initialCollapsedState,
		initialWidth: fittedSidebarWidth,
		shouldForceExpand,
	};
};

const useSideNavEnabled = (view: string | undefined) => {
	const { match } = useContext(RoutesContext);

	const [spaceKey] = usePageSpaceKey();

	return (
		match?.query?.mode !== 'global' &&
		view &&
		[CONTAINER_HOME_ID, CONTAINER_BLOG_ID, CONTAINER_HOME_LOADING_ID, ADMIN_HOME_ID].includes(
			view,
		) &&
		// The side nav is not desirable in Company Hub, because it hides its space from the user.
		// But only CONTAINER_XXX represent space-related side navs, ADMIN_HOME_ID is for site-related admin side nav.
		(!isCompanyHubSpaceKey(spaceKey) || view === ADMIN_HOME_ID)
	);
};

const useSideNavResize = (shouldForceExpand: boolean) => {
	const { expandLeftSidebar } = usePageLayoutResize();

	// CCT-951 TEMPORARY WORKAROUND to allow resizing of side-nav when viewing a Whiteboard (rendered in an iFrame)
	// Can be removed when DSP-5931 is fixed and @atlaskit/page-layout is bumped
	const onResizeStart = useCallback(() => {
		(
			document.querySelector(`iframe[title="${WHITEBOARD_IFRAME_TITLE}"]`) as HTMLElement
		)?.style.setProperty('pointer-events', 'none');
	}, []);

	const onResizeEnd = useCallback(
		(leftSidebarState: any) => {
			// CCT-951 TEMPORARY WORKAROUND to allow resizing of side-nav when viewing a Whiteboard (rendered in an iFrame)
			// Can be removed when DSP-5931 is fixed and @atlaskit/page-layout is bumped
			(
				document.querySelector(`iframe[title="${WHITEBOARD_IFRAME_TITLE}"]`) as HTMLElement
			)?.style.removeProperty('pointer-events');

			confluenceLocalStorageInstance.setItem(
				PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_WIDTH,
				leftSidebarState.leftSidebarWidth,
			);
			if (shouldForceExpand && !leftSidebarState.leftSidebarWidth) {
				expandLeftSidebar();
			}
		},
		[shouldForceExpand, expandLeftSidebar],
	);

	return { onResizeStart, onResizeEnd };
};

const getSideNavigation = (view: string | undefined, isSpaceSettingsScreen: boolean) => {
	switch (view) {
		case CONTAINER_HOME_ID:
			return <SpaceNavigation isSpaceSettingsScreen={isSpaceSettingsScreen} />;
		case CONTAINER_BLOG_ID:
			return <SpaceNavigation isBlogNavigation isSpaceSettingsScreen={false} />;
		case CONTAINER_HOME_LOADING_ID:
			return <SpaceNavigationLoadingSkeleton />;
		case ADMIN_HOME_ID:
			return <AdminNavigationLoader />;
	}
	return null;
};

export const SideNavigationSwitcher: FC<PageLayoutProps & skipLinkProp> = ({
	view,
	skiplinkText,
	isSpaceSettingsScreen = false,
}) => {
	const isSideNavEnabled = useSideNavEnabled(view);
	const { initialCollapsedState, initialWidth, shouldForceExpand } = useSideNavState();
	const { onResizeStart, onResizeEnd } = useSideNavResize(shouldForceExpand);
	const sideNavigation = getSideNavigation(view, isSpaceSettingsScreen);
	const intl = useIntl();
	const isOnEditRoute = useIsEditorPage();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	const getTooltipTextI18n = (isLeftSidebarCollapsed) =>
		isLeftSidebarCollapsed ? i18n.expandTooltip : i18n.collapseTooltip;
	const getExpanderButtonLabelI18n = (isLeftSidebarCollapsed) =>
		isLeftSidebarCollapsed ? i18n.expandLabel : i18n.collapseLabel;
	return isSideNavEnabled ? (
		<div css={confSidebarWrapperStyles}>
			<LeftSidebar
				testId="grid-left-sidebar"
				onResizeStart={onResizeStart}
				onResizeEnd={onResizeEnd}
				width={initialWidth}
				collapsedState={initialCollapsedState}
				skipLinkTitle={skiplinkText}
				id="AkSideNavigation"
				overrides={{
					ResizeButton: {
						render: (Component, props) =>
							shouldForceExpand ? (
								<Fragment />
							) : (
								<Tooltip
									content={
										<ShortcutVisualizer
											contentBefore={
												<FormattedMessage {...getTooltipTextI18n(props.isLeftSidebarCollapsed)} />
											}
											shortcut={
												isLiveEditMode || isOnEditRoute
													? SIDE_NAVIGATION_SHORTCUT_IN_EDIT
													: SIDE_NAVIGATION_SHORTCUT
											}
											isEditorShortcut={isOnEditRoute}
										/>
									}
									hideTooltipOnClick
									position="right"
									testId="sidebar-collapse-tooltip"
									ignoreTooltipPointerEvents
								>
									<Component
										aria-label={intl.formatMessage(
											getExpanderButtonLabelI18n(props.isLeftSidebarCollapsed),
										)}
										{...props}
									/>
								</Tooltip>
							),
					},
				}}
			>
				{sideNavigation}
				{!shouldForceExpand && <ExpandKeyboardShortcut />}
			</LeftSidebar>
		</div>
	) : null;
};
